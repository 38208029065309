import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';

import ProductCardList from 'components/ProductCardList';
import Pagination from 'components/Pagination';
import AlgoliaFilters from 'components/AlgoliaFilters';

import { FILTER_NAVIGATION_PARAMS } from 'utils/algolia/constants';
import useOnViewport from 'hooks/useOnViewport';
import useScreenRecognition from 'hooks/useScreenRecognition';
import useAlgoliaResponse from 'hooks/useAlgoliaResponse';
import { gtmService } from 'services/gtmService';

import Helpers from '../ArticleListFilter/helpers';

import { IPropsProductListFilter } from './model';

import './ProductListFilter.scss';

const COMPONENT_ID = 'productListFilter';

const ProductListFilter: FC<IPropsProductListFilter> = (props): ReactElement | null => {
  const { isMobile, isMiddleTablet } = useScreenRecognition();
  const [filters, setFilters] = useState<AppFilters.IChosenFilterItemData[]>();
  const isSmallDevice = isMobile || isMiddleTablet;
  const productsCardListRef = useRef<HTMLDivElement>(null);

  const {
    filterButtonsTexts,
    categories,
    currentPageId,
    filterSections,
    categorySectionTitle,
    customGlobalStyles,
    showResultsBtn,
    isFixedFilters,
    counterFirstWord,
    counterMiddleWord,
    isArticlesCounterDisplayed,
    filtersTitle,
    clearAllBtn,
    filterMenuBgColor,
    checkboxStyles,
    productsListConfigs: {
      limitOnPageDesktop,
      limitOnPageMobile,
      isShowCTABtn,
      isShowLabels,
      CTABtn,
      sorryMessage,
    },
    defaultProducts,
    masterPageId,
    masterPageLink,
    showFilters,
    showManuallySelectedProducts,
    productsLinks,
    lang,
    removeAppliedFilterAriaLabel,
    listingName,
  } = props;

  const isProductsCardListInViewport = useOnViewport(productsCardListRef, '-75px');

  const limit = isSmallDevice ? limitOnPageMobile : limitOnPageDesktop;

  const additionalFilterParams =
    showManuallySelectedProducts && productsLinks?.length
      ? Helpers.getAdditionalFilterParams(productsLinks)
      : '';

  const {
    itemsToRender,
    activeListPage,
    handleActiveListPage,
    pageCount,
    saveAlgoliaHitsResponse,
    handleAlgoliaFiltersUsed,
    handleHitsResponseActivated,
    isHitsResponseActivated,
    itemsTotal,
    loading,
    countFilteredItems,
  } = useAlgoliaResponse<ProductCard.IProductCard>(
    limit,
    defaultProducts,
    showManuallySelectedProducts,
    productsLinks,
    filters
  );

  const [products, setProducts] = useState<ProductCard.IProductCard[]>(itemsToRender);

  const filteredProducts = useRef<ProductCard.IProductCard[]>([]);
  const currentFilteredProducts = useRef<ProductCard.IProductCard[]>([]);

  useEffect(() => {
    if (!loading && itemsToRender.length) {
      setProducts(itemsToRender);
      filteredProducts.current = itemsToRender;
    }
  }, [loading, itemsToRender]);

  useEffect(() => {
    let eventTimeoutId;
    const timeoutId = setTimeout(() => {
      if (
        JSON.stringify(currentFilteredProducts.current) !== JSON.stringify(filteredProducts.current)
      ) {
        currentFilteredProducts.current = filteredProducts.current;
        eventTimeoutId = gtmService.emitProductListingView(
          listingName,
          currentFilteredProducts.current
        );
      }
    }, 1000);

    return () => {
      timeoutId && clearTimeout(timeoutId);
      eventTimeoutId && clearTimeout(eventTimeoutId);
    };
  }, [products]);

  return (
    <div data-test="ProductListFilter" className="nf-product-list-filter">
      <div className="pagination-anchor-block" id={COMPONENT_ID}>
        {(categories?.length || filterSections.length) &&
        process.env.GATSBY_ALGOLIA_INDEX_PREFIX ? (
          <AlgoliaFilters
            indexName={`${process.env.GATSBY_ALGOLIA_INDEX_PREFIX}-products`}
            filterSections={filterSections}
            checkboxStyles={checkboxStyles}
            customGlobalStyles={customGlobalStyles}
            filterButtonsTexts={filterButtonsTexts}
            categories={categories}
            currentPageId={currentPageId}
            masterPageId={masterPageId}
            masterPageLink={masterPageLink}
            idInMainDOM={COMPONENT_ID}
            showResultsBtn={showResultsBtn}
            clearAllBtn={clearAllBtn}
            filterMenuBgColor={filterMenuBgColor}
            categorySectionTitle={categorySectionTitle}
            saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
            handleAlgoliaFiltersUsed={handleAlgoliaFiltersUsed}
            handleActiveListPage={handleActiveListPage}
            handleHitsResponseActivated={handleHitsResponseActivated}
            isHitsResponseActivated={isHitsResponseActivated}
            lang={lang}
            categoryFacetName={FILTER_NAVIGATION_PARAMS.productCategoryFacet}
            additionalFilterParams={additionalFilterParams}
            showFilters={showFilters}
            setFilters={setFilters}
            isSmallDevice={isSmallDevice}
            removeAppliedFilterAriaLabel={removeAppliedFilterAriaLabel}
            isFixedPosition={isFixedFilters}
            filtersTitle={filtersTitle}
          />
        ) : null}
        <div ref={productsCardListRef} className="products-card-list-wrapper">
          {!loading ? (
            <ProductCardList
              products={products}
              shopBtnShow={isShowCTABtn}
              CTABtn={CTABtn}
              isShowLabels={isShowLabels}
              sorryMessage={sorryMessage}
              isProductsCardListInViewport={isProductsCardListInViewport}
              isSmallDevice={isSmallDevice}
              countFilteredItems={countFilteredItems}
              itemsTotal={itemsTotal}
              counterFirstWord={counterFirstWord}
              counterMiddleWord={counterMiddleWord}
              isArticlesCounter={isArticlesCounterDisplayed}
            />
          ) : null}
        </div>
        {itemsTotal > limit ? (
          <Pagination
            handleActiveListPage={handleActiveListPage}
            pageCount={pageCount}
            active={activeListPage}
            scrollTargetId={COMPONENT_ID}
          />
        ) : null}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentProductListConfigs on IProductListConfigs {
    productListConfigsLimitOnPage
    productListConfigsLimitOnPageMobile
    productListConfigsShowCTABtn
    productListConfigsShowLabels
    productListConfigsCTAButton {
      properties {
        ...FragmentNFButton
      }
    }
    productListConfigsSorryMessage
    additionalMessage
  }

  fragment FragmentFilterProductList on IFilterProductList {
    showProductsFiltersSidebar
    isFixedFilters
    counterFirstWord
    counterMiddleWord
    isArticlesCounterDisplayed
    filtersTitle
    filterProductListFilterSections {
      properties {
        header
        isOpen
        operand
        attributeForFaceting
        variant
        filterItems {
          id
          title
        }
      }
    }
  }

  fragment FragmentProductsFilters on IProductsFilters {
    categoriesList {
      properties {
        filterableCategories {
          id
          title
          relatedPage
          relatedPageLink
          masterPageLink
          masterPageId
        }
        categorySectionTitle
      }
    }
    checkboxStyles {
      properties {
        bgColor {
          ...FragmentColorProps
        }
        signColor {
          ...FragmentColorProps
        }
      }
    }
    clearAllBtn {
      properties {
        defaultTextColor {
          ...FragmentColorProps
        }
        title
      }
    }
    customGlobalStyles {
      properties {
        defaultControlsBgColor {
          ...FragmentColorProps
        }
        defaultControlsTextColor {
          ...FragmentColorProps
        }
      }
    }
    showResultsBtn {
      properties {
        defaultBgColor {
          ...FragmentColorProps
        }
        defaultTextColor {
          ...FragmentColorProps
        }
        title
      }
    }
    filterMenuBgColor {
      ...FragmentColorProps
    }
    filterButtonsTexts {
      properties {
        filtersSelectLabel
        filtersHaveSelectedLabel
        filtersTogglerButtonText
        closeButtonText
        seeMoreButtonText
        seeLessButtonText
      }
    }
    masterPageId
    masterPageLink
    removeAppliedFilterAriaLabel
  }
`;

export default ProductListFilter;
