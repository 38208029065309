import { parseNFButton } from 'components/common/NFButton/parsers';
import { getLSProducts } from 'utils/recentProductsHelper';
import { RecentlyViewedProductsProps } from './model';

const parseRecentlyViewedProducts = (
  viewedProductsConfig: RecentlyViewedProducts.IRecentlyViewedProducts,
  extraConfig: {
    [key: string]: any;
    carouselControls: AppCarousel.ICarouselControls;
  } = { carouselControls: { ariaLabelNext: 'Next', ariaLabelPrev: 'Previous' } },
  lang: string = ''
): RecentlyViewedProductsProps => ({
  title: viewedProductsConfig?.recentlyViewedTitle || extraConfig.pdpSettings.recentlyViewedTitle,
  bgColor: viewedProductsConfig?.sectionBg?.[0]?.properties?.colorPicker?.label || 'grey',
  products: getLSProducts(lang, 6) || [],
  shopBtnShow: viewedProductsConfig?.shopBtnsShow === '1',
  btn: viewedProductsConfig?.sectionCtaBtn?.length
    ? parseNFButton(viewedProductsConfig?.sectionCtaBtn?.[0]?.properties)
    : undefined,
  fontColor: viewedProductsConfig?.sectionFontColor?.[0]?.properties?.colorPicker?.label || 'navy',
  carouselControls: extraConfig.carouselControls,
});

export default parseRecentlyViewedProducts;
