import ParserHelpers, { IStructureToPropsMapResult } from 'utils/parseHelpers';

import parseRecentlyViewedProducts from 'components/RecentlyViewedProducts/parsers';
import { parseProductsFilters } from 'components/ProductListFilter/parsers';
import { parseBanner } from 'components/Banner/parsers';
import { parseNFButton } from 'components/common/NFButton/parsers';
import { parseExtendedFeaturedArticles } from 'components/ExtendedFeaturedArticles/parsers';

import { getPageIdFromUmbracoId } from 'utils/helpers';
import { IUmbracoProductListIncomeData, IUmbracoProductListParsedData } from './models';

const parseUmbracoProductListIncomeData = (
  param: IUmbracoProductListIncomeData
): IUmbracoProductListParsedData => {
  const page = param.umbracoProductList.nodes[0];
  const data: IStructureToPropsMapResult = ParserHelpers.getStructureToPropsMap(
    param.umbracoProductList.nodes[0].body
  );
  const featuredArticlesData = data['Featured Articles']?.properties;
  const showFilters = data['Filter Product List']?.properties?.showProductsFiltersSidebar === '1';
  const isFixedFilters = Boolean(Number(data['Filter Product List']?.properties?.isFixedFilters));
  const counterFirstWord = data['Filter Product List']?.properties?.counterFirstWord;
  const counterMiddleWord = data['Filter Product List']?.properties?.counterMiddleWord;
  const filtersTitle = data['Filter Product List']?.properties?.filtersTitle;
  const isArticlesCounterDisplayed = Boolean(
    Number(data['Filter Product List']?.properties?.isArticlesCounterDisplayed)
  );
  const currentPageId = param.umbracoProductList.nodes[0].umbracoId;
  const { lang } = param.umbracoProductList.nodes[0];
  const recentlyViewedProductsData = data['Recently viewed products']?.properties;
  const extendedFeaturedArticles =
    data['Featured Articles From Symptoms & Pain Advice Sections']?.properties;
  const showManuallySelectedProducts =
    param.umbracoProductList.nodes[0]?.showManuallySelectedProducts === '1';

  const productFilters = page?.isProductListingPartOfKidsHub
    ? {
        ...param.productFilters,
        masterPageId: getPageIdFromUmbracoId(page.umbracoId),
        masterPageLink: page.link,
      }
    : param.productFilters;

  const result: IUmbracoProductListParsedData = {
    filterProductList:
      data['Filter Product List'] || param.productFilters
        ? parseProductsFilters(
            {
              ...productFilters,
              filterSections:
                data['Filter Product List']?.properties?.filterProductListFilterSections,
            },
            showFilters,
            isFixedFilters,
            counterFirstWord,
            counterMiddleWord,
            isArticlesCounterDisplayed,
            filtersTitle,
            currentPageId,
            param.productListConfigs,
            param.defaultProducts.nodes,
            showManuallySelectedProducts,
            param.productsLinks,
            lang
          )
        : undefined,
    banner: data['Page Banner']
      ? parseBanner(data['Page Banner'].properties, { breadCrumbs: param.breadCrumbs })
      : undefined,
    relatedArticles: featuredArticlesData
      ? {
          bgColor: featuredArticlesData.featuredArticlesBgColor,
          title: featuredArticlesData.featuredArticlesSectionTitle,
          subtitle: featuredArticlesData.featuredArticlesSectionSubtitle,
          btn: featuredArticlesData.featuredArticlesCTAButtonData?.length
            ? { ...parseNFButton(featuredArticlesData.featuredArticlesCTAButtonData[0].properties) }
            : undefined,
          articles: featuredArticlesData.featuredArticlesData,
          mode: featuredArticlesData.featuredArticlesSelectedArticleMode,
          articlesByTag: param.featuredArticlesByTag.nodes,
          carouselControls: param.carouselControls,
        }
      : undefined,

    recentlyViewedProducts: recentlyViewedProductsData
      ? parseRecentlyViewedProducts(recentlyViewedProductsData, param, lang)
      : undefined,

    extendedFeaturedArticles: extendedFeaturedArticles
      ? parseExtendedFeaturedArticles({
          extendedFeaturedArticles,
          articlesByLink: [
            ...param?.extendedFeaturedArticlesPainAdviceByLink?.nodes,
            ...param?.extendedFeaturedArticlesSymptomsByLink?.nodes,
          ],
          articlesByTag: [
            ...param?.extendedFeaturedArticlesPainAdviceByTag?.nodes,
            ...param?.extendedFeaturedArticlesSymptomsByTag?.nodes,
          ],
          carouselControls: param.carouselControls,
        })
      : undefined,
    additionalMessageData: {
      additionalMessage: param.productListConfigs?.additionalMessage,
    },
  };

  return result;
};

export default parseUmbracoProductListIncomeData;
